import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const DialogMessage = React.memo(({ onClose, message }) => (
  <Dialog onClose={onClose} open={!!message}>
    <DialogTitle>Alert</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
            Ok
      </Button>
    </DialogActions>
  </Dialog>
));

DialogMessage.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default DialogMessage;
