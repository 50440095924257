import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, MuiThemeProvider } from './app/lib/theme';

import App from './app/app/app';
import './index.scss';

/**
 * Application entry point
 */

const run = () => {
  ReactDOM.render(
    <MuiThemeProvider theme={createTheme()}>
      <App />
    </MuiThemeProvider>, document.getElementById('react-app-container'),
  );
};

if (['complete', 'loaded', 'interactive'].includes(document.readyState) && document.body)
  run();
else
  window.addEventListener('DOMContentLoaded', run, false);
