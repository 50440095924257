import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@material-ui/core';
import {
  CallEnd, ScreenShare, StopScreenShare, Mic, MicOff, Videocam, VideocamOff,
} from '@material-ui/icons';
import styles from './videoController.scss';

const VideoController = React.memo(({
  isSharingScreen, isAudioOn, isVideoOn, onLeaveRoom, onMuteTrack, onScreenShare, onStopScreenShare,
}) => (
  <div className={styles.container}>
    <IconButton size="medium" classes={{ root: styles.leaveButton }} onClick={() => onLeaveRoom()}>
      <CallEnd />
    </IconButton>
    {isSharingScreen
      ? (
        <IconButton
          size="medium"
          onClick={() => onStopScreenShare()}
          classes={{ root: styles.activeButton }}
        ><StopScreenShare />
        </IconButton>
      )
      : <IconButton size="medium" onClick={() => onScreenShare()}><ScreenShare /></IconButton>}
    {isAudioOn
      ? <IconButton size="medium" onClick={() => onMuteTrack('audio', false)}><Mic /></IconButton>
      : (
        <IconButton
          size="medium"
          onClick={() => onMuteTrack('audio', true)}
          classes={{ root: styles.activeButton }}
        ><MicOff />
        </IconButton>
      )}
    {isVideoOn
      ? <IconButton size="medium" onClick={() => onMuteTrack('video', false)}><Videocam /></IconButton>
      : (
        <IconButton
          size="medium"
          onClick={() => onMuteTrack('video', true)}
          classes={{ root: styles.activeButton }}
        ><VideocamOff />
        </IconButton>
      )}
  </div>
));

VideoController.propTypes = {
  isSharingScreen: PropTypes.bool,
  isAudioOn: PropTypes.bool,
  isVideoOn: PropTypes.bool,
  onLeaveRoom: PropTypes.func,
  onMuteTrack: PropTypes.func,
  onScreenShare: PropTypes.func,
  onStopScreenShare: PropTypes.func,
};

export default VideoController;
