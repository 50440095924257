import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import trackEnum from 'chat-twilio-rxjs/videobox/trackEnum';
import { log } from 'react-core/lib/logger';
import styles from './participant.scss';

const Participant = React.memo(({
  participant, activeTrack, onClick, updateFactor, fullScreenRef,
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [isScreenSharing, setIsScreenSharing] = useState(false);

  const audioRef = useRef();
  const videoRef = useRef();
  const videoScreenRef = useRef();

  // participant events
  useEffect(() => {
    const trackSubscribed = (track) => {
      if (track.kind === 'video')
        setVideoTracks((vTracks) => [...vTracks, track]);
      else if (track.kind === 'audio')
        setAudioTracks((aTracks) => [...aTracks, track]);
      else if (track.kind === 'data') {
        track.on('message', (data) => log([data], 'video_room', 3));
        log(['Got data track subscribed'], 'video_room', 3);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video')
        setVideoTracks((vTracks) => vTracks.filter((v) => v !== track));
      else if (track.kind === 'audio')
        setAudioTracks((aTracks) => aTracks.filter((a) => a !== track));
      else
        log(['Data track unsubscribed'], 'video_room', 3);
    };

    setVideoTracks(publicationsToTracks(participant.videoTracks));
    setAudioTracks(publicationsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
    };
  }, [participant, updateFactor]);

  // video tracks attach
  useEffect(() => {
    const videoTrack = videoTracks.find((a) => a.name === trackEnum.CAMERA || a.name === trackEnum.CAMERAAFTERJOIN);
    const screenVideoTrack = videoTracks.find((a) => a.name === trackEnum.SHARE);

    const videoScreenRefEl = videoScreenRef.current;
    const videoRefEl = videoRef.current;

    if (videoTrack || screenVideoTrack) {
      if (screenVideoTrack) {
        screenVideoTrack.attach(videoScreenRefEl);
        screenVideoTrack.attach(fullScreenRef.current);
        setIsScreenSharing(true);
        onClick(screenVideoTrack);
      } else {
        videoTrack.attach(fullScreenRef.current);
        setIsScreenSharing(false);
        onClick(videoTrack);
      }
      if (videoTrack)
        videoTrack.attach(videoRefEl);
    }

    return () => {
      if (screenVideoTrack) {
        screenVideoTrack.detach();
        videoScreenRefEl.load();
      }
      if (videoTrack) {
        videoTrack.detach();
        // for last frozen frame refresh
        videoRefEl.load();
      }
    };
  }, [fullScreenRef, onClick, videoTracks]);

  // audio tracks attach
  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack)
      audioTrack.attach(audioRef.current);

    return () => {
      if (audioTrack)
        audioTrack.detach();
    };
  }, [audioTracks]);

  const publicationsToTracks = (trackMap) => Array.from(trackMap.values())
    .map((publication) => publication.track)
    .filter((track) => !!track);

  const isActiveTrack = (track) => {
    if (!track || !activeTrack)
      return false;
    return track.mediaStreamTrack.id === activeTrack.mediaStreamTrack.id;
  };

  const videoTrack = videoTracks.find((a) => a.name === trackEnum.CAMERA || a.name === trackEnum.CAMERAAFTERJOIN);
  const screenVideoTrack = videoTracks.find((a) => a.name === trackEnum.SHARE);

  return (
    <div className="participant">
      <video
        className={[styles.video, (isActiveTrack(videoTrack) ? styles.isActive : '')].join(' ')}
        ref={videoRef}
        width="200"
        height="150"
        autoPlay
        onClick={() => onClick(videoTrack)}
      ><track kind="captions" />
      </video>
      <video
        className={[styles.video, (isActiveTrack(screenVideoTrack) ? styles.isActive : '')].join(' ')}
        ref={videoScreenRef}
        width="200"
        height="150"
        autoPlay
        onClick={() => onClick(screenVideoTrack)}
        style={{ display: isScreenSharing ? '' : 'none' }}
      ><track kind="captions" />
      </video>
      <audio ref={audioRef} autoPlay><track kind="captions" /></audio>
    </div>
  );
});

Participant.propTypes = {
  participant: PropTypes.shape({
    videoTracks: PropTypes.object,
    audioTracks: PropTypes.object,
    on: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  activeTrack: PropTypes.object,
  onClick: PropTypes.func,
  updateFactor: PropTypes.number,
  fullScreenRef: PropTypes.element,
};

export default Participant;
