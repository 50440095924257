import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  onCreatePreview,
  previewStartedSubject,
  startRoomSubject,
  roomJoiningFailSubject,
  videoCallsIsSupported,
} from 'chat-twilio-rxjs/videobox/twilioVideoEventSources/twilioVideoEventSources';

import tokenChangedSubject, {
  tokenFailedSubject,
} from 'chat-twilio-rxjs/videobox/twilioToken/twilioVideoTokenRetreival';

import { IconButton } from '@material-ui/core';
import { Videocam, Visibility, VisibilityOff } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogMessage from '../dialogMessage/dialogMessage';
import useWindowSize from '../../lib/hooks/useWindowSize';
import styles from './videoPreview.scss';

const VideoPreview = React.memo(({ onStartRoom }) => {
  const videoRef = useRef();
  const audioRef = useRef();
  const { widthSize, heightSize } = useWindowSize();
  const [isPreviewActive, setIsPreviewActive] = useState(false);
  const [videoTrack, setVideoTrack] = useState();
  const [audioTrack, setAudioTrack] = useState();
  const [roomLoading, setRoomLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const id = new URLSearchParams(window.location.search).get('id');
  const callsSupported = videoCallsIsSupported();

  useEffect(() => {
    const subscriptions = [];

    if (!callsSupported)
      return () => { };

    subscriptions.push(
      previewStartedSubject.subscribe((tracks) => {
        setIsPreviewActive(true);
        tracks.forEach((track) => {
          if (track.kind === 'video') {
            setVideoTrack(track);
            track.attach(videoRef.current);
          }
          if (track.kind === 'audio') {
            setAudioTrack(track);
            track.attach(audioRef.current);
          }
        });
      }),
    );

    subscriptions.push(
      startRoomSubject.subscribe(() => setRoomLoading(true)),
    );

    subscriptions.push(
      roomJoiningFailSubject.subscribe((error) => {
        setRoomLoading(false);
        if (error.message.includes('token must be a string'))
          setAlertMessage('Login to Staffshift site and refresh this page');
        else if (error.message.includes('Requested device not found'))
          setAlertMessage('Connect your microphone');
        else if (error.message.includes('Invalid Access Token'))
          setAlertMessage('Refresh the page');
        else if (error.message.includes('Could not start video source'))
          setAlertMessage('Reconnect your video camera');
        else if (error.name && error.name.includes('NotAllowedError'))
          setAlertMessage('Allow using microphone and video camera in this site settings');
      }),
    );

    subscriptions.push(
      tokenChangedSubject.subscribe((token) => setRoomLoading(!token)),
    );

    subscriptions.push(
      tokenFailedSubject.subscribe(() => setRoomLoading(false)),
    );

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [callsSupported]);

  const onStopReview = useCallback(() => {
    if (videoTrack)
      videoTrack.disable();
    if (audioTrack)
      audioTrack.disable();
    setIsPreviewActive(false);
    setVideoTrack(null);
    setAudioTrack(null);
  }, [videoTrack, audioTrack]);

  return (
    <div>
      <DialogMessage onClose={() => setAlertMessage(null)} message={alertMessage} />
      <div className={styles.buttonsContainer}>
        {!callsSupported && (
          <div>Calls are NOT supported. Please update or download latest version of
          one of these browsers: Chrome, Safari, Firefox, Edge, Opera.
          </div>
        )}
        {!id && callsSupported && <div>It&apos;s wrong url</div>}
        {callsSupported && id && (
          <React.Fragment>
            {roomLoading
              ? <CircularProgress color="inherit" />
              : (
                <React.Fragment>
                  <div className={styles.button} style={{ marginRight: '40px' }}>
                    <IconButton size="medium" onClick={() => onStartRoom(id)}>
                      <Videocam />
                    </IconButton>
                    <div className={styles.buttonLabel}>Join</div>
                  </div>

                  {isPreviewActive
                    ? (
                      <div className={styles.button}>
                        <IconButton size="medium" classes={{ root: styles.activeButton }} onClick={onStopReview}>
                          <VisibilityOff />
                        </IconButton>
                        <div className={styles.buttonLabel}>Stop preview</div>
                      </div>
                    )
                    : (
                      <div className={styles.button}>
                        <IconButton size="medium" onClick={() => onCreatePreview(widthSize * 0.5, heightSize * 0.5)}>
                          <Visibility />
                        </IconButton>
                        <div className={styles.buttonLabel}>Preview</div>
                      </div>
                    )}
                </React.Fragment>
              )}
          </React.Fragment>
        )}
      </div>
      <div className={styles.videoContainer}>
        <video
          ref={videoRef}
          width={widthSize * 0.5}
          height={heightSize * 0.5}
          autoPlay
          style={{ display: isPreviewActive ? '' : 'none' }}
        ><track kind="captions" />
        </video>
        <audio ref={audioRef} autoPlay><track kind="captions" /></audio>
      </div>
    </div>
  );
});

VideoPreview.propTypes = {
  onStartRoom: PropTypes.func,
};

export default VideoPreview;
