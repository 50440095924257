import React from 'react';
import PropTypes from 'prop-types';

import VideoRoom from '../../components/videoRoom';
import VideoPreview from '../../components/videoPreview';
import styles from './call.scss';

const Call = React.memo(({ room, onStartRoom }) => (
  <div className={styles.background}>
    { room
      ? <VideoRoom room={room} />
      : <VideoPreview onStartRoom={onStartRoom} />}
  </div>
));

Call.propTypes = {
  room: PropTypes.shape({
    localParticipant: PropTypes.object,
    participants: PropTypes.object,
    disconnect: PropTypes.func,
  }),
  onStartRoom: PropTypes.func,
};

export default Call;
