import React, {
  useCallback, useState, useEffect,
} from 'react';

import tokenChangedSubject from 'chat-twilio-rxjs/videobox/twilioToken/twilioVideoTokenRetreival';
import startRoom,
{
  startRoomSubject,
  roomJoinedSubject,
  roomClosedSubject,
} from 'chat-twilio-rxjs/videobox/twilioVideoEventSources/twilioVideoEventSources';
import useWindowSize from '../lib/hooks/useWindowSize';
import Call from '../widgets/call';

import styles from './app.scss';

const App = React.memo(() => {
  const [token, setToken] = useState();
  const { widthSize } = useWindowSize();
  const [roomName, setRoomName] = useState();
  const [room, setRoom] = useState();

  useEffect(() => {
    const subscriptions = [];

    subscriptions.push(tokenChangedSubject.subscribe((t) => setToken(t)));
    subscriptions.push(startRoomSubject.subscribe(({ name }) => setRoomName(name)));
    subscriptions.push(roomJoinedSubject.subscribe((r) => {
      window.history.replaceState({}, null, `/?id=${r.name}`);
      setRoom(r);
    }));
    subscriptions.push(roomClosedSubject.subscribe(() => setRoom(null)));

    return () => subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, [roomName, token]);

  const handleStartRoom = useCallback((roomN) => {
    startRoom(roomN, widthSize, token);
  }, [widthSize, token]);

  return (
    <div className={styles.app}>
      <Call
        room={room}
        onStartRoom={handleStartRoom}
      />
    </div>
  );
});

export default App;
