import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';

import { log, registerLogCategory } from 'react-core/lib/logger';
import {
  participantConnectedSubject,
  participantDisconnectedSubject,
  roomClosedSubject,
  createScreenTrack,
  screenTrackCreatedSubject,
  screenTrackStoppedSubject,
  getVideoCamTrack,
} from 'chat-twilio-rxjs/videobox/twilioVideoEventSources/twilioVideoEventSources';
import trackEnum from 'chat-twilio-rxjs/videobox/trackEnum';
import useWindowSize from '../../lib/hooks/useWindowSize';
import DialogMessage from '../dialogMessage/dialogMessage';
import styles from './videoRoom.scss';

import VideoController from '../videoController';
import Participant from '../participant';

const VideoRoom = React.memo(({ room }) => {
  const remoteMediaRef = useRef();
  const { widthSize, heightSize } = useWindowSize();
  const [isAudioOn, setIsAudioOn] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const [screenTrack, setScreenTrack] = useState(null);
  const [currentTrack, setCurrentTrack] = useState();
  const [participants, setParticipants] = useState([]);
  const [alertMessage, setAlertMessage] = useState();
  const [isMuting, setIsMuting] = useState();

  // room events
  useEffect(() => {
    if (!room)
      return () => { };

    registerLogCategory(
      'video_room',
      'Twilio video room events',
    );
    log(['Room created'], 'video_room', 3);

    const subscriptions = [];

    // set video icon
    const videoTrack = getTracks(room.localParticipant).find((a) => a.kind === 'video');
    setIsVideoOn(!!videoTrack);

    setParticipants((prevParticipants) => [...prevParticipants, room.localParticipant]);

    room.participants.forEach((participant) => {
      log(['Participant already in room', participant.identity], 'video_room', 3);
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    });

    subscriptions.push(
      participantConnectedSubject.subscribe((participant) => {
        log(['Joining participant', participant.identity], 'video_room', 3);
        setParticipants((prevParticipants) => [...prevParticipants, participant]);
      }),
    );

    subscriptions.push(
      participantDisconnectedSubject.subscribe((participant) => {
        log(['Remote Participant left the room', participant.identity], 'video_room', 3);
        setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
      }),
    );

    subscriptions.push(
      roomClosedSubject.subscribe(() => {
        log(['You left the room'], 'video_room', 3);
        setParticipants([]);
      }),
    );

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
      if (room && room.localParticipant.state === 'connected') {
        room.localParticipant.tracks.forEach((trackPublication) => {
          trackPublication.track.stop();
        });
      }
      room.disconnect();
      room.removeAllListeners();
    };
  }, [room]);

  // screen share
  useEffect(() => {
    const screenSubscription = screenTrackCreatedSubject.subscribe(async (track) => {
      await room.localParticipant.publishTrack(track, { priority: 'high' });
      setScreenTrack(track);
    });

    const screenStoppedSubscription = screenTrackStoppedSubject.subscribe(async (track) => {
      await room.localParticipant.unpublishTrack(track);
      setScreenTrack(null);
    });

    return () => {
      screenSubscription.unsubscribe();
      screenStoppedSubscription.unsubscribe();
    };
  }, [room.localParticipant]);

  // screen resize
  useEffect(() => {
    const video = remoteMediaRef.current.querySelector('video');
    if (video && video.width !== widthSize) {
      video.width = widthSize;
      video.height = heightSize;
    }
  });

  // show video on big screen
  useEffect(() => {
    if (currentTrack)
      currentTrack.attach(remoteMediaRef.current);
  }, [currentTrack, onUpdateMainScreen]);

  // focus on last participant if chages
  useEffect(() => {
    onUpdateMainScreen();
  }, [participants, onUpdateMainScreen]);

  // before leave page, because react hook return doesn't work for this
  useEffect(() => {
    window.addEventListener('beforeunload', onLeaveRoom);
    return () => {
      window.removeEventListener('beforeunload', onLeaveRoom);
    };
  }, [onLeaveRoom]);

  // callbacks
  const onUpdateMainScreen = useCallback(() => {
    if (participants.length > 0) {
      const tracksPublications = participants[participants.length - 1].videoTracks;
      const tracks = Array.from(tracksPublications.values())
        .map((publication) => publication.track)
        .filter((track) => track !== null);
      if (tracks.length > 0)
        setCurrentTrack(tracks[tracks.length - 1]);
    }
  }, [participants]);

  const onLeaveRoom = useCallback(() => {
    if (room)
      room.disconnect();
  }, [room]);

  const onStopScreenShare = useCallback(() => {
    screenTrack.stop();
  }, [screenTrack]);

  const onParticipantClick = useCallback((track, refresh = false) => {
    if (!track && !refresh)
      return;
    setCurrentTrack(track);
  }, []);

  const onMuteCallback = useCallback(async (trackKind, turnOn) => {
    try {
      // add camera video track if it is not present
      if (trackKind === 'video'
        && !getTracks(room.localParticipant)
          .find((a) => a.kind === 'video' && a.name !== trackEnum.SHARE)) {
        if (isMuting)
          return;
        setIsMuting(true);
        const devices = await navigator.mediaDevices.enumerateDevices();
        if (devices.find((a) => a.kind === 'videoinput')) {
          const videoTrack = await getVideoCamTrack(widthSize);
          // room.localParticipant.addTrack(videoTrack);//publishTrack(videoTrack, { priority: 'high' });
          // await room.localParticipant.publishTrack(videoTrack);
          await room.localParticipant.publishTrack(videoTrack.mediaStreamTrack, {
            name: trackEnum.CAMERAAFTERJOIN,
          });
          setCurrentTrack(videoTrack);
        } else
          setAlertMessage('Connect your video camera');

        setIsMuting(false);
      }
      getTracks(room.localParticipant).forEach((track) => {
        if (track.kind === trackKind && track.name !== trackEnum.SHARE) {
          if (turnOn)
            track.enable();

          else if (track.name === trackEnum.CAMERAAFTERJOIN) {
            track.stop();
            room.localParticipant.unpublishTrack(track);
          } else
            track.disable();
          if (trackKind === 'audio')
            setIsAudioOn(turnOn);
          else
            setIsVideoOn(turnOn);
        }
      });
    } catch (error) {
      setIsMuting(false);
    }
  }, [room.localParticipant, widthSize, isMuting]);

  const getTracks = (participant) => Array.from(participant.tracks.values())
    .filter((publication) => publication.track)
    .map((publication) => publication.track);
  return (
    <React.Fragment>
      <VideoController
        isSharingScreen={screenTrack !== null}
        isAudioOn={isAudioOn}
        isVideoOn={isVideoOn}
        onLeaveRoom={onLeaveRoom}
        onMuteTrack={onMuteCallback}
        onScreenShare={createScreenTrack}
        onStopScreenShare={onStopScreenShare}
      />

      <div className={styles.containerStrip}>
        {participants.map((participant) => (
          <Participant
            key={participant.sid}
            participant={participant}
            onClick={onParticipantClick}
            activeTrack={currentTrack}
            updateFactor={participant.tracks.size}
            fullScreenRef={remoteMediaRef}
          />
        ))}
      </div>
      <video ref={remoteMediaRef} width={widthSize} height={heightSize}>
        <track kind="captions" />
      </video>
      <DialogMessage onClose={() => setAlertMessage(null)} message={alertMessage} />
    </React.Fragment>
  );
});

VideoRoom.propTypes = {
  room: PropTypes.shape({
    localParticipant: PropTypes.object,
    participants: PropTypes.object,
    disconnect: PropTypes.func,
    removeAllListeners: PropTypes.func,
  }),
};

export default VideoRoom;
